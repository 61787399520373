// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-altergott-webdesign-marke-js": () => import("./../../../src/pages/altergott-webdesign-marke.js" /* webpackChunkName: "component---src-pages-altergott-webdesign-marke-js" */),
  "component---src-pages-altergott-webdesign-werbe-js": () => import("./../../../src/pages/altergott-webdesign-werbe.js" /* webpackChunkName: "component---src-pages-altergott-webdesign-werbe-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-gasthauslinde-js": () => import("./../../../src/pages/gasthauslinde.js" /* webpackChunkName: "component---src-pages-gasthauslinde-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-naturrepublik-js": () => import("./../../../src/pages/naturrepublik.js" /* webpackChunkName: "component---src-pages-naturrepublik-js" */),
  "component---src-pages-pluto-js": () => import("./../../../src/pages/pluto.js" /* webpackChunkName: "component---src-pages-pluto-js" */),
  "component---src-pages-pluto-marke-js": () => import("./../../../src/pages/pluto-marke.js" /* webpackChunkName: "component---src-pages-pluto-marke-js" */),
  "component---src-pages-termin-js": () => import("./../../../src/pages/termin.js" /* webpackChunkName: "component---src-pages-termin-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

